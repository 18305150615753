import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const registerSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  checked: Yup.boolean().oneOf([true], "Accept Terms & Conditions is required"),
});

export const KYCSchema = Yup.object().shape({
  stateOfOrigin: Yup.string().required("State of Origin is required"),
  stateOfResidence: Yup.string().required("State of Residence is required"),
  educationLevel: Yup.string().required("Education Level is required"),
  gender: Yup.string().required("gender is required"),
  age: Yup.string().required("Age is required"),
  howDidYouHearAboutUs: Yup.string().required(
    "How did you hear about us is required"
  ),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const createNewPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
