import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, []); // eslint-disable-line
  return <Fragment>{children}</Fragment>;
};

export default AuthProvider;
