import React from "react";
import logo from "../../assets/image/logo/logo.png";
import loginImage from "../../assets/authImages/loginImage.png";
import { useForm } from "react-hook-form";
import { createNewPasswordSchema } from "../../schema/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../components/Shared/Loading";
import AuthProvider from "../../Provider/AuthProvider";

const CreateNewPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createNewPasswordSchema) });

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <AuthProvider>
      <div className="container mx-auto p-1">
        <div className="w-36 h-16 absolute top-0 left-0">
          <img src={logo} alt="logo" className="w-full h-full" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 justify-between items-center md:gap-10">
          <div className="w-full md:w-[450px] px-1">
            <div className="flex flex-col justify-center items-center">
              <div className="space-y-1 text-center">
                <h1 className="font-bold text-2xl py-2 text-dark">
                  Create New Password
                </h1>

                <p className="text-gray-400 font-semibold">
                  This password should be different from the previous password.
                </p>
              </div>

              <div className="divider my-2"></div>
              <form
                className="w-full space-y-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="border-none w-full ">
                  <label className="label">
                    <span className="label-text">New password</span>
                  </label>
                  <span className="text-error">{errors.password?.message}</span>
                  <input
                    type="password"
                    placeholder="new password"
                    className="input input-bordered w-full focus:outline-none"
                    {...register("password")}
                  />
                </div>
                <div className="border-none w-full ">
                  <label className="label">
                    <span className="label-text">Confirm password</span>
                  </label>
                  <span className="text-error">
                    {errors.confirmPassword?.message}
                  </span>
                  <input
                    type="password"
                    placeholder="confirm password"
                    className="input input-bordered w-full focus:outline-none"
                    {...register("confirmPassword")}
                  />
                </div>

                <div className="mt-3 w-full mx-auto">
                  {isSubmitting ? (
                    <div className="flex justify-center items-center flex-col">
                      <Loading />
                    </div>
                  ) : (
                    <button className="btn btn-outline hover:text-white text-white hover:bg-lightGreen hover:opacity-90 bg-lightGreen rounded-md btn-block">
                      Send Recovery Link
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="w-full hidden md:block relative h-screen">
            <img
              src={loginImage}
              alt="login"
              className="w-full h-full object-cover rounded-md"
            />

            <div className="absolute bottom-0 left-0 w-full  bg-[#01473799] bg-opacity-60 rounded-md px-4 py-12">
              <p className="font-bold text-3xl text-white leading-[40px]">
                “The tallest trees sometimes grow from the smallest seeds”
              </p>

              <p className="text-white font-semibld text-xl mt-3">
                ---- Matshona Dhliwayo
              </p>
            </div>
          </div>
        </div>
      </div>
    </AuthProvider>
  );
};

export default CreateNewPassword;
