import React from "react";
import MyCourseDetailLayout from "../../Layouts/Dashboard/MyCourseDetailLayout";
import VideoPlayer from "../../components/Dashboard/VideoPlayer";
import { IoMdTime } from "react-icons/io";
import { MdOutlineVideocam } from "react-icons/md";
import { IoBriefcaseOutline } from "react-icons/io5";
import ReactStars from "react-stars";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import CourseReview from "../../components/Dashboard/CourseReview";
import CourseSyllabus from "../../components/Dashboard/CourseSyllabus";

const MyCourseDetail = () => {
  return (
    <MyCourseDetailLayout courseTitle={"Intro To Content Marketing"}>
      <div className="flex justify-between items-start w-full gap-2 container mx-auto">
        <section className="px-2 mt-2 bg-white mb-5 w-3/4">
          <div className="grid grid-cols-1 md:grid-cols-2 py-3 w-full rounded-md ">
            <VideoPlayer />
            <div className="px-2">
              <h2 className="text-xl font-bold text-dark">
                Introduction To Content Marketing
              </h2>

              <p className="text-base text-lightGray">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu
                lobortis elementum nibh tellus molestie nunc. Mattis aliquam
                faucibus purus in massa tempor nec.
              </p>

              <span className="px-3 py-2 bg-lightGray2 mt-3 inline-block text-dark rounded">
                Marketing
              </span>

              <div className="mt-4 grid grid-cols-4">
                <div className="flex flex-row justify-center items-center gap-2 text-lightGray">
                  <IoMdTime size={25} className="" />
                  <h3 className="text-base font-semibold">8hr 30m</h3>
                  <span className="ml-3">*</span>
                </div>

                <div className="flex flex-row justify-center items-center gap-2 text-lightGray">
                  <MdOutlineVideocam size={25} className="" />
                  <h3 className="text-base font-semibold">12 Videos</h3>
                  <span className="ml-3">*</span>
                </div>

                <div className="flex flex-row justify-center items-center gap-2 text-lightGray">
                  <IoBriefcaseOutline size={25} className="" />
                  <h3 className="text-base font-semibold">9 Projects</h3>
                </div>
              </div>

              <div className="mt-4 flex justify-start items-center gap-3">
                <div className="flex justify-start items-center gap-2">
                  <h3 className="text-lg font-semibold text-dark">4/5</h3>
                  <ReactStars
                    count={5}
                    size={24}
                    color2={"#ffd700"}
                    value={4}
                    edit={false}
                  />
                </div>

                <div className="flex justify-start items-center gap-2">
                  <IoChatboxEllipsesOutline />
                  <p className="text-lightGray">20 Reviews</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 h-[1px] w-full bg-lightGray opacity-40"></div>

          <div className="mt-5">
            <h2 className="text-xl font-bold text-dark">Expected Outcomes</h2>
            <p className="text-base text-lightGray">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu
              lobortis elementum nibh tellus molestie nunc. Mattis aliquam
              faucibus purus in massa tempor nec. Sed felis eget velit aliquet.
              Elementum tempus egestas sed sed risus pretium quam. Amet mauri
            </p>
          </div>
          <CourseSyllabus />
          <div className="mt-5 h-[1px] w-full bg-lightGray opacity-40"></div>
        </section>
        <CourseReview />
      </div>
    </MyCourseDetailLayout>
  );
};

export default MyCourseDetail;
