import { Link } from "react-router-dom";
import logo from "../../assets/image/logo/logo.png";
import "../../styles/style.css";
import { CiSearch } from "react-icons/ci";
import { Fragment, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { CiGrid41 } from "react-icons/ci";

const DesktopNavigation = () => {
  return (
    <header className="header fixed top-0 right-0 left-0 bg-white z-10 shadow-lg">
      <div className="navbar">
        <div className="nav-sub">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" className="w-32" />
            </Link>
          </div>
          <nav className="header__category">
            <ul>
              <li>
                <i className="bi bi-grid"></i>
                <span className="ps-3">Categories</span>
                <ul class="cat-submenu">
                  <li>
                    <a href="course-details.html">English Learning</a>
                  </li>
                  <li>
                    <a href="course-details.html">Web Development</a>
                  </li>
                  <li>
                    <a href="course-details.html">Logo Design</a>
                  </li>
                  <li>
                    <a href="course-details.html">Motion Graphics</a>
                  </li>
                  <li>
                    <a href="course-details.html">Video Edition</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <form className="relative w-full">
            <input
              type="search"
              className="input input-bordered w-full pl-7 focus:outline-none"
              placeholder="Search..."
            />
            <CiSearch className="absolute top-0 mt-3 left-0" size={20} />
          </form>
        </div>

        <div className="links">
          <a href="/" className="btn btn-theme-1">
            Become an instructor
          </a>
          <a href="/auth/login" className="btn btn-theme">
            Log in
          </a>
          <a href="/auth/signup" className="btn btn-theme-color">
            Sign up
          </a>
        </div>
      </div>
    </header>
  );
};

const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <div className=" flex justify-between items-center bg-white px-1 shadow-lg">
        <div className="flex justify-start items-center gap-1">
          <button>
            <CiGrid41
              className="text-2xl text-lightGreen text-bold"
              size={30}
              onClick={() => setIsOpen(true)}
            />
          </button>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" className="w-32" />
            </Link>
          </div>
        </div>
        <a href="/auth/login" className="btn btn-theme">
          Log in
        </a>
      </div>
      <div
        className={`fixed ${
          !isOpen && "hidden"
        } top-0 left-0 bottom-0 w-4/5 bg-white h-screen z-10 shadow-lg px-2`}
      >
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" className="w-40" />
          </Link>
        </div>
        <button
          className="absolute top-0 right-0 p-2"
          onClick={() => setIsOpen(false)}
        >
          <IoCloseOutline
            className="text-2xl text-lightGreen text-bold"
            size={25}
          />
        </button>
        <div>
          <nav className="header__category">
            <ul>
              <li>
                <i className="bi bi-grid"></i>
                <span className="ps-3">Categories</span>
                <ul className="cat-submenu">
                  <li>
                    <a href="course-details.html">English Learning</a>
                  </li>
                  <li>
                    <a href="course-details.html">Web Development</a>
                  </li>
                  <li>
                    <a href="course-details.html">Logo Design</a>
                  </li>
                  <li>
                    <a href="course-details.html">Motion Graphics</a>
                  </li>
                  <li>
                    <a href="course-details.html">Video Edition</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <form className="relative w-full my-4">
            <input
              type="search"
              className="input input-bordered w-full pl-7 focus:outline-none"
              placeholder="Search..."
            />
            <CiSearch className="absolute top-0 mt-3 left-0" size={20} />
          </form>
          <div className="flex justify-center flex-col items-start gap-3">
            <a href="/" className="btn btn-theme-1 w-full">
              Become an instructor
            </a>
            <a href="/auth/login" className="btn btn-theme w-full">
              Log in
            </a>
            <a href="/auth/signup" className="btn btn-theme-color w-full">
              Sign up
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function Navbar() {
  return (
    <Fragment>
      <div className="hidden md:block">
        <DesktopNavigation />
      </div>
      <div className="block md:hidden">
        <MobileNavigation />
      </div>
    </Fragment>
  );
}

export default Navbar;
