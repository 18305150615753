import React, { Fragment } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosNotificationsOutline } from "react-icons/io";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { IoBookOutline } from "react-icons/io5";
import { GiGraduateCap } from "react-icons/gi";
import { TbReport } from "react-icons/tb";

const SideNavigationBar = () => {
  return (
    <Fragment>
      <div className=" bg-white px-7 pt-14 pb-5 w-full md:w-[350px]">
        <div className="flex justify-center items-center flex-col">
          <div className="avatar online">
            <div className="w-24 rounded-full">
              <img
                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"
                alt=""
              />
            </div>
          </div>
          <h2 className=" font-bold text-xl">James Osagie</h2>
          <div className="flex justify-start items-center gap-4 mt-2">
            <IoIosNotificationsOutline className="text-lightGray" size={25} />
            <MdOutlineEmail className="text-lightGray" size={25} />
            <HiOutlineChatAlt2 className="text-lightGray" size={25} />
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-lightGray opacity-30 "></div>
      <div className="bg-white px-4 pb-4 pt-5 w-full md:w-[350px]">
        <div className="w-full h-1 rounded bg-[#E5E7EB]">
          <div className="w-3/4 h-full rounded bg-lightGreen"></div>
        </div>
        <h2 className="py-1 text-lightGray text-base">Profile Completion</h2>

        <select className="select focus:outline-none border-lightGray w-full">
          <option disabled selected>
            Profile Setup Tasks(2/3)
          </option>
          <option>Game of Thrones</option>
          <option>Lost</option>
          <option>Breaking Bad</option>
          <option>Walking Dead</option>
        </select>

        <div className="flex flex-col gap-4 mt-2">
          <div className="flex justify-start items-center gap-4 w-full bg-lightGray2 p-1 rounded">
            <IoBookOutline
              className="text-lightGray px-1 bg-white rounded-full border-2 border-lightGray"
              size={40}
            />
            <h2 className="text-dark font-bold">
              2{" "}
              <span className="text-lightGray font-semibold">
                Ongoing Courses
              </span>
            </h2>
          </div>
          <div className="flex justify-start items-center gap-4 w-full bg-lightGray2 p-1 rounded">
            <GiGraduateCap
              className="text-lightGray px-1 bg-white rounded-full border-2 border-lightGray"
              size={40}
            />
            <h2 className="text-dark font-bold">7 Completed Courses</h2>
          </div>

          <div className="flex justify-start items-center gap-4 w-full bg-lightGray2 p-1 rounded">
            <TbReport
              className="text-lightGray px-1 bg-white rounded-full border-2 border-lightGray"
              size={40}
            />
            <h2 className="text-dark font-bold">2 Reports</h2>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideNavigationBar;
