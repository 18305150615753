import React from "react";
import { Link, useLocation } from "react-router-dom";
import { dashboardTopNavbar } from "../../constants/dashboardTopNavbar";

const TopNavigationBar = () => {
  const location = useLocation();
  return (
    <div className="w-[95%] mx-auto">
      <ul className="flex justify-start items-center space-x-4">
        {dashboardTopNavbar.map((item) => (
          <li
            key={item.id}
            className={`${
              location.pathname === item.path &&
              "border-b-2 border-b-lightGreen"
            }`}
          >
            <Link to={item.path}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopNavigationBar;
