import React from "react";
import certicate from "../../assets/image/dashboard/certificate.png";
import { BsDownload } from "react-icons/bs";

const Certificate = () => {
  return (
    <section className="container mx-auto">
      <div className="px-3 py-4 bg-white shadow-md rounded-md">
        <h1 className="font-bold text-xl text-dark">My Certificates</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 my-4">
        {[1, 2, 3, 4].map((item) => (
          <div
            className="card shadow-xl text-center flex flex-col justify-center items-center relative w-full"
            key={item}
          >
            <button className="absolute top-0 right-1 bg-transparent text-lightGreen rounded-full border-lightGreen border p-2">
              <BsDownload size={20} />
            </button>
            <div className="card-body">
              <div className="w-full mx-auto flex justify-center items-center flex-col">
                <img src={certicate} alt="certificate" />
              </div>
            </div>
            <div className="card-actions justify-center bg-white w-full py-3 px-1">
              <p className="font-semibold text-base">
                Public Speaking For Beginn...
              </p>
              <button className="btn btn-link text-lightGreen">
                Download Certificate
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Certificate;
