import axios from "axios";

const axiosInsatnce = axios.create({
  baseURL: "http://learn-api.edoinnovates.com/api/v1/",
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export { axiosInsatnce };
