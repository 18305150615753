import React from "react";
import logo from "../../assets/image/logo/logo.png";
import KycImage from "../../assets/authImages/KYCImage.png";
import { useForm } from "react-hook-form";
import { KYCSchema } from "../../schema/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../components/Shared/Loading";

const KYCAuth = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(KYCSchema) });

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="container mx-auto p-1">
      <div className="w-36 h-16 absolute top-0 left-0">
        <img src={logo} alt="logo" className="w-full h-full" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 justify-between items-center md:gap-10">
        <div className="w-full px-1 md:px-16 py-10 mt-10">
          <div className="flex flex-col justify-center items-center">
            <div className="space-y-1 text-center">
              <h1 className="font-bold text-xl  md:text-2xl py-2 text-dark">
                Please Answer A few Questions For Us
              </h1>
            </div>

            <div className="divider my-2"></div>
            <form
              className="w-full space-y-5"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-3">
                <div className="border-none w-full">
                  <label className="label">
                    <span className="label-text">State of Origin</span>
                  </label>
                  <span className="text-error">
                    {errors.stateOfOrigin?.message}
                  </span>
                  <select className="selection" {...register("stateOfOrigin")}>
                    <option value="Abia">Abia</option>
                    <option value="Adamawa">Adamawa</option>
                    <option value="Akwa Ibom">Akwa Ibom</option>
                    <option value="Anambra">Anambra</option>
                  </select>
                </div>
                <div className="border-none w-full">
                  <label className="label">
                    <span className="label-text">State of Residence</span>
                  </label>
                  <span className="text-error">
                    {errors.stateOfResidence?.message}
                  </span>
                  <select
                    className="selection"
                    {...register("stateOfResidence")}
                  >
                    <option value="Abia">Abia</option>
                    <option value="Adamawa">Adamawa</option>
                    <option value="Akwa Ibom">Akwa Ibom</option>
                    <option value="Anambra">Anambra</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1  md:gap-3">
                <div className="border-none w-full">
                  <label className="label">
                    <span className="label-text">Education Level</span>
                  </label>
                  <span className="text-error">
                    {errors.educationLevel?.message}
                  </span>
                  <select className="selection" {...register("educationLevel")}>
                    <option value="Primary">Primary</option>
                    <option value="Secondary">Secondary</option>
                    <option value="Tertiary">Tertiary</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div className="border-none w-full">
                  <label className="label">
                    <span className="label-text">Gender</span>
                  </label>
                  <span className="text-error">{errors.gender?.message}</span>
                  <select className="selection" {...register("gender")}>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="border-none w-full relative">
                  <label className="label">
                    <span className="label-text">Age</span>
                  </label>
                  <span className="text-error">{errors.age?.message}</span>
                  <input
                    type="text"
                    placeholder="Enter here"
                    className="input input-bordered w-full focus:outline-none"
                    {...register("age")}
                  />
                </div>

                <div className="border-none w-full">
                  <label className="label">
                    <span className="label-text">
                      How did you hear about us?
                    </span>
                  </label>
                  <span className="text-error">
                    {errors.howDidYouHearAboutUs?.message}
                  </span>
                  <select
                    className="selection"
                    {...register("howDidYouHearAboutUs")}
                  >
                    <option value="Google">Google</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Twitter">Twitter</option>
                  </select>
                </div>
              </div>

              <div className="mt-3 w-full mx-auto">
                {isSubmitting ? (
                  <div className="flex justify-center items-center flex-col">
                    <Loading />
                  </div>
                ) : (
                  <button className="btn btn-outline hover:text-white text-white hover:bg-lightGreen hover:opacity-90 bg-lightGreen rounded-md btn-block">
                    Finish
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="w-full hidden md:block relative h-full">
          <img
            src={KycImage}
            alt="login"
            className="w-full h-full object-cover rounded-md"
          />

          <div className="absolute bottom-0 left-0 w-full  bg-[#01473799] bg-opacity-60 rounded-md px-4 py-12">
            <p className="font-bold text-3xl text-white leading-[40px]">
              “Education is the most powerful weapon which you can use to change
              the world”
            </p>

            <p className="text-white font-semibld text-xl mt-3">
              --- Nelson Mandela
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCAuth;
