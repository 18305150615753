import React from "react";
import { useNavigate } from "react-router-dom";
import google from "../../assets/image/logos_google.png";
import skill from "../../assets/image/skill-icons_aws-dark.png";
import art from "../../assets/image/image 6.png";
import edo from "../../assets/image/logo/logo.png";
import { SlGraduation } from "react-icons/sl";
import { HiOutlineLibrary } from "react-icons/hi";
import { IoBookOutline } from "react-icons/io5";
import { HiOutlineSquaresPlus } from "react-icons/hi2";
import ReactStars from "react-stars";
import thumbnail from "../../assets/image/landing/thumbnail.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CiSearch } from "react-icons/ci";
import { IoMicOutline } from "react-icons/io5";
import image1 from "../../assets/image/landing/landing (1).png";
import image2 from "../../assets/image/landing/landing (2).png";
import image3 from "../../assets/image/landing/landing (3).png";
import feature1 from "../../assets/image/landing/feature (2).png";
import feature2 from "../../assets/image/landing/feature (1).png";
import {
  features,
  landingCourseCategory,
  landingReviews,
} from "../../constants/landingCourseCategory";

import { motion } from "framer-motion";
import {
  FadeDownEffect,
  FadeUpEffect,
  FlipEffect,
} from "../../components/AOS/Index";

const Home = () => {
  const navigate = useNavigate();

  return (
    <main className="md:pt-10">
      {/* hero section */}
      <section className="py-10">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 justify-between items-center gap-1 md:gap-10 space-x-10 px-1">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1.5 }}
              className="text-lightGray text-base text-left"
            >
              Start To Success
            </motion.p>
            <h1 className="text-2xl md:text-5xl font-bold text-left leading-[6rem] py-4">
              Access To <span className="text-lightGreen">100+</span> Courses
              From <span className="text-lightGreen"> 50+ </span>Instructors &
              Institutions.
            </h1>

            <motion.form
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 2 }}
              className="flex justify-start items-center space-x-4 mt-5 w-full relative"
            >
              <input
                type="text"
                placeholder="Search for courses"
                className="border border-gray-300 py-2 rounded-md w-full px-10 focus:outline-none focus:border-none"
              />

              <CiSearch
                style={{ fontSize: "25px" }}
                className="absolute -top-1 left-0 mt-3"
              />

              <IoMicOutline
                style={{ fontSize: "25px" }}
                className="absolute -top-1 right-24 mt-3"
              />
              <button className="bg-lightGreen text-white p-2 rounded-md">
                Search
              </button>
            </motion.form>
          </motion.div>

          <motion.div
            initial={{ scale: 0, opacity: 0, zoom: 0 }}
            animate={{ scale: 1, opacity: 1, zoom: 1 }}
            transition={{ duration: 2 }}
            className="w-full"
          >
            <div className="flex justify-center items-center gap-3 ">
              <img src={image1} alt="image1" className="w-[316px] h-[392px]" />
              <div className="md:flex flex-col justify-start items-start space-y-4 w-full hidden">
                <img
                  src={image2}
                  alt="image2"
                  className="w-[192px] h-[254px]"
                />
                <img
                  src={image3}
                  alt="image3"
                  className="w-[250px] h-[194px]"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      {/* end section */}

      {/* startup section */}
      <section className="bg-lightGreen py-5 px-1">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-10">
            <div className="flex flex-col md:flex-row text-center md:text-left justify-start items-center space-x-4">
              <div className="p-1 bg-white rounded-full">
                <SlGraduation
                  style={{ fontSize: "40px" }}
                  className="text-lightGreen"
                />
              </div>
              <h1 className="text-[12px] md:text-base font-semibold text-white">
                Learn The <br />
                Essential Skills
              </h1>
            </div>

            <div className="flex flex-col md:flex-row text-center md:text-left justify-start items-center space-x-4">
              <div className="p-1 bg-white rounded-full">
                <HiOutlineLibrary
                  style={{ fontSize: "40px" }}
                  className="text-lightGreen"
                />
              </div>
              <h1 className="text-[12px] md:text-base font-semibold text-white">
                Earn Certificates <br />
                And Degrees
              </h1>
            </div>

            <div className="flex flex-col md:flex-row text-center md:text-left justify-start items-center space-x-4">
              <div className="p-1 bg-white rounded-full">
                <IoBookOutline
                  style={{ fontSize: "40px" }}
                  className="text-lightGreen"
                />
              </div>
              <h1 className="text-[12px] md:text-base font-semibold text-white">
                Get Ready For <br /> The Next Career
              </h1>
            </div>

            <div className="flex flex-col md:flex-row text-center md:text-left justify-start items-center space-x-4">
              <div className="p-1 bg-white rounded-full">
                <HiOutlineSquaresPlus
                  style={{ fontSize: "40px" }}
                  className="text-lightGreen"
                />
              </div>
              <h1 className="text-[12px] md:text-base font-semibold text-white">
                Master At <br />
                Different Areas
              </h1>
            </div>
          </div>
        </div>
      </section>
      {/* end section */}

      {/* categories */}
      <section className="top-categories pt-50 pb-50">
        <div className="container mx-auto">
          <div className="">
            <div className="w-full py-5 ">
              <h2 className="text-3xl font-bold">Top Categories</h2>
              <p className="font-normal mt-2 leading-8 text-base ">
                Explore courses from various disciplines ranging from soft
                skills to practical industrial hands-on skills.
              </p>
            </div>
          </div>
          <ul className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-1">
            {landingCourseCategory.map((cat) => (
              <FlipEffect key={cat.id}>
                <li className="px-[16px] py-16 bg-white text-lightGray text-center font-semibold text-base">
                  {cat.category}
                </li>
              </FlipEffect>
            ))}
          </ul>
        </div>
      </section>
      {/* end section */}

      {/* popular courses */}
      <section className="px-2 py-10">
        <FadeUpEffect>
          <div className="container mx-auto">
            <div className="w-full md:w-1/3">
              <h2 className="text-3xl font-bold">Highest Reviewed</h2>
              <p className="font-normal mt-2 leading-7 mb-4 text-base ">
                Our most popular courses, reviewed and rated by our learners
                overtime. You cannot go wrong with them.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
              {landingReviews.map((review) => (
                <div className="card shadow-lg" key={review.id}>
                  <div className="w-full">
                    <img src={thumbnail} alt="" className="w-full" />
                    <div className="play-video">
                      <i className="fa-regular fa-circle-play"></i>
                    </div>
                  </div>
                  <div className="popular-body p-4">
                    <div className="category">
                      <span
                        className={`px-2 py-1 rounded inline-block my-2 ${
                          review.category === "Beginner"
                            ? "bg-[#DEF7EC]"
                            : review.category === "Intermediate"
                            ? "bg-[#FDF6B2]"
                            : "bg-[#FCE8F3]"
                        }
                          ${
                            review.category === "Beginner"
                              ? "text-[#03543F]"
                              : review.category === "Intermediate"
                              ? "text-[#723B13]"
                              : "text-[#99154B]"
                          }
                          
                      `}
                      >
                        {review.category}
                      </span>
                    </div>
                    <h4 className="font-bold text-base">{review.title}</h4>
                    <div className="rating flex justify-start items-center gap-2">
                      <ReactStars
                        count={5}
                        size={24}
                        color2={"#ffd700"}
                        value={review.rating}
                        edit={false}
                      />

                      <span className="text-lightGray">
                        ({review.learners} learners)
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </FadeUpEffect>
      </section>
      {/* end section */}

      {/* signup section */}
      <section className="px-2 mt-10">
        <FadeDownEffect>
          <div className="w-full md:w-[650px] mx-auto px-2 py-4 bg-[#F9FBFB] rounded shadow-md">
            <div className="flex flex-col md:flex-row text-center md:text-left justify-center md:justify-around items-center">
              <div className="flex justify-start items-center space-x-4">
                <svg
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden md:block"
                >
                  <rect y="0.5" width="48" height="48" rx="24" fill="#112428" />
                  <path
                    d="M18.6667 27.1667V31.1667M24 27.1667V31.1667M29.3333 27.1667V31.1667M12 36.5H36M12 21.8333H36M12 17.8333L24 12.5L36 17.8333H12ZM13.3333 21.8333H34.6667V36.5H13.3333V21.8333Z"
                    stroke="#DEF7EC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>
                  <h1 className="text-2xl font-bold text-dark">Need Help?</h1>

                  <p className="text-lightGray text-base">
                    Not sure where to begin or which course to take?
                  </p>
                </div>{" "}
              </div>

              <button
                className="bg-lightGreen md:btn-md text-white p-2 rounded-md block mt-3"
                onClick={() => navigate("/auth/signup")}
              >
                Sign Up
              </button>
            </div>
          </div>
        </FadeDownEffect>
      </section>

      <section className="px-2 py-10 mt-10">
        <FadeUpEffect>
          <div className="container mx-auto flex flex-col md:flex-row justify-center items-center gap-4">
            {features.map((feature, index) => {
              return (
                <div
                  className={`flex justify-between items-center gap-2 px-2 py-1 rounded shadow-md ${
                    index === 1 ? "bg-[#EBF5FF]" : "bg-[#FDFDEA]"
                  }`}
                >
                  <div className="w-full md:w-3/4">
                    <h2 className="font-bold text-[18px] text-lightGreen">
                      {feature.title}
                    </h2>
                    <p className="mt-2 text-sm text-lightGreen">
                      {feature.description}
                    </p>
                    <button className="btn-md bg-lightGreen text-white mt-4 rounded-lg">
                      {feature.buttonText}
                    </button>
                  </div>
                  <div className="w-full md:w-1/4 hidden md:block">
                    {index === 0 ? (
                      <img
                        src={feature1}
                        alt="feature1"
                        className="w-full rounded-lg"
                      />
                    ) : (
                      <img
                        src={feature2}
                        alt="feature2"
                        className="w-full rounded-lg"
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </FadeUpEffect>
      </section>
      {/* partner section */}
      <section className="py-10 mt-5 px-2">
        <FadeUpEffect>
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row justify-start items-center text-center md:text-left mb-3 md:gap-10">
              <div className="w-full md:w-1/4">
                <h2 className="font-bold text-2xl md:text-4xl text-lightGreen">
                  Our Partners On Mission
                </h2>
                <p className="text-sm text-lightGray">
                  Here are the stakeholders working with us to create impact in
                  the south-south
                </p>

                <button className="btn-md text-lightGreen btn-outline bg-[#F3FAF7] mt-4 rounded-lg hidden md:block">
                  View All Partners
                </button>
              </div>
              <div className="w-full md:w-3/4">
                <div className="slider-container">
                  <div className="grid grid-cols-2 md:grid-cols-4 justify-between items-center">
                    <div className="thumb-img">
                      <img src={google} width={150} height={150} alt="" />
                    </div>
                    <div className="thumb-img">
                      <img src={skill} width={150} height={150} alt="" />
                    </div>
                    <div className="thumb-img">
                      <img src={art} width={150} height={150} alt="" />
                    </div>
                    <div className="thumb-img">
                      <img src={edo} width={150} height={150} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <button className="btn-md text-lightGreen btn-outline bg-[#F3FAF7] mt-4 rounded-lg block md:hidden">
                View All Partners
              </button>
            </div>
          </div>
        </FadeUpEffect>
      </section>
    </main>
  );
};

export default Home;
