import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const MyCourseDetailLayout = ({ children, courseTitle }) => {
  return (
    <div className="container mx-auto ">
      <div className=" breadcrumbs text-base bg-white rounded-md px-2 py-3 mt-3">
        <ul className="space-x-3">
          <li>
            <Link to={"/"} className="block space-x-2">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.70692 0.792786C8.51939 0.605315 8.26508 0.5 7.99992 0.5C7.73475 0.5 7.48045 0.605315 7.29292 0.792786L0.292919 7.79279C0.110761 7.98139 0.00996641 8.23399 0.0122448 8.49619C0.0145233 8.75838 0.119692 9.0092 0.3051 9.1946C0.490508 9.38001 0.741321 9.48518 1.00352 9.48746C1.26571 9.48974 1.51832 9.38894 1.70692 9.20679L1.99992 8.91379V15.4998C1.99992 15.765 2.10528 16.0194 2.29281 16.2069C2.48035 16.3944 2.7347 16.4998 2.99992 16.4998H4.99992C5.26514 16.4998 5.51949 16.3944 5.70703 16.2069C5.89456 16.0194 5.99992 15.765 5.99992 15.4998V13.4998C5.99992 13.2346 6.10528 12.9802 6.29281 12.7927C6.48035 12.6051 6.7347 12.4998 6.99992 12.4998H8.99992C9.26514 12.4998 9.51949 12.6051 9.70703 12.7927C9.89456 12.9802 9.99992 13.2346 9.99992 13.4998V15.4998C9.99992 15.765 10.1053 16.0194 10.2928 16.2069C10.4803 16.3944 10.7347 16.4998 10.9999 16.4998H12.9999C13.2651 16.4998 13.5195 16.3944 13.707 16.2069C13.8946 16.0194 13.9999 15.765 13.9999 15.4998V8.91379L14.2929 9.20679C14.4815 9.38894 14.7341 9.48974 14.9963 9.48746C15.2585 9.48518 15.5093 9.38001 15.6947 9.1946C15.8801 9.0092 15.9853 8.75838 15.9876 8.49619C15.9899 8.23399 15.8891 7.98139 15.7069 7.79279L8.70692 0.792786Z"
                  fill="#6B7280"
                />
              </svg>{" "}
              <span> My Courses</span>
            </Link>
          </li>
          <li>
            <a href="">{courseTitle}</a>
          </li>
        </ul>
      </div>
      {children}
    </div>
  );
};

export default MyCourseDetailLayout;
