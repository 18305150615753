import React from "react";
import { courseCategory } from "../../constants/courseCategory";
import OverViewLayout from "../../Layouts/Dashboard/OverViewDashboardLayout";

const OverView = () => {
  return (
    <OverViewLayout>
      <div className="bg-white py-3 w-full px-5 rounded-md">
        <h1 className="text-xl font-bold text-dark">Ongoing Courses</h1>
      </div>

      <div className="bg-white py-3 w-full px-4 rounded-md mt-4 relative space-y-4">
        <span className="text-sm text-lightGreen absolute top-0 right-0">
          25%
        </span>
        <div className="h-1 w-full bg-lightGray2 rounded-md mt-2">
          <div className="w-1/4 h-full bg-lightGreen rounded-md"></div>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div>
            <div className="clear-both"></div>
            <h1 className="text-xl font-bold text-neutral">
              Administration For Beginners
            </h1>
            <p className="text-sm text-lightGray">
              Module 2 - Lesson 1: Introduction To Management Practices
            </p>
          </div>
          <div className="flex justify-start items-center gap-3">
            <button className="btn btn-outline">Course Details</button>
            <button className="btn btn-outline bg-lightGreen text-white hover:bg-lightGreen">
              Resume Learning
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white py-3 w-full px-5 rounded-md mt-4 relative space-y-4">
        <span className="text-sm text-lightGreen absolute top-0 right-0">
          75%
        </span>
        <div className="h-1 w-full bg-lightGray2 rounded-md mt-2">
          <div className="w-3/4 h-full bg-lightGreen rounded-md"></div>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div>
            <div className="clear-both"></div>
            <h1 className="text-xl font-bold text-neutral">
              Effective Communication In The Workplace
            </h1>
            <p className="text-sm text-lightGray">
              Module 1 - Lesson 8: Formal Speech Practice
            </p>
          </div>
          <div className="flex justify-start items-center gap-3">
            <button className="btn btn-outline">Course Details</button>
            <button className="btn btn-outline bg-lightGreen text-white hover:bg-lightGreen">
              Resume Learning
            </button>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="bg-white py-3 w-full px-1 rounded-md">
          <h1 className="text-[18px] font-bold text-dark">
            Explore Other Categories
          </h1>
        </div>

        <div className="grid grid-cols-3 gap- mt-4">
          {courseCategory.map((cat) => {
            return (
              <div
                className="bg-lightGray2 border px-4 py-8 flex justify-center items-center flex-col w-full  rounded-sm"
                key={cat}
              >
                <h1 className="text-sm font-semibold text-lightGray">{cat}</h1>
              </div>
            );
          })}
        </div>
      </div>
    </OverViewLayout>
  );
};

export default OverView;
