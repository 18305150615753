import React from "react";
import { IoVideocamOutline } from "react-icons/io5";
import { GrNotes } from "react-icons/gr";

const CourseSyllabus = () => {
  return (
    <section className="w-full mt-5">
      <h1 className="text-xl font-bold text-dark">Course Syllabus</h1>
      <div className="w-full">
        <div className="collapse collapse-arrow bg-base-200">
          <input type="radio" name="my-accordion-2" defaultChecked />
          <div className="collapse-title text-xl font-medium">
            Module 1: Orientation
          </div>
          <div className="collapse-content">
            <p className="text-dark font-semibold flex justify-start items-center gap-1">
              <IoVideocamOutline size={20} className="text-lightGray" />
              <span className="ml-2">Topic 1</span>
            </p>
            <p className="text-dark font-semibold flex justify-start items-center gap-1">
              <IoVideocamOutline size={20} className="text-lightGray" />
              <span className="ml-2">Topic 2</span>
            </p>
            <p className="text-dark font-semibold flex justify-start items-center gap-1">
              <IoVideocamOutline size={20} className="text-lightGray" />
              <span className="ml-2">Topic 3</span>
            </p>
          </div>
        </div>
        <div className="collapse collapse-arrow bg-base-200">
          <input type="radio" name="my-accordion-3" />
          <div className="collapse-title text-xl font-medium">
            Module 2: Marketing In Practice
          </div>
          <div className="collapse-content">
            <p className="text-dark font-semibold flex justify-start items-center gap-1">
              <IoVideocamOutline size={20} className="text-lightGray" />
              <span className="ml-2">Topic 1</span>
            </p>
            <p className="text-dark font-semibold flex justify-start items-center gap-1">
              <IoVideocamOutline size={20} className="text-lightGray" />
              <span className="ml-2">Topic 2</span>
            </p>
            <p className="text-dark font-semibold flex justify-start items-center gap-1">
              <IoVideocamOutline size={20} className="text-lightGray" />
              <span className="ml-2">Topic 3</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseSyllabus;
