import React from "react";
import logo from "../../assets/image/logo/logo.png";

import { CiSearch } from "react-icons/ci";

const TopHeader = () => {
  return (
    <div className="flex justify-between items-center bg-white py-1 px-1 fixed top-0 left-0 right-0 z-10">
      <div className="">
        <img src={logo} alt="logo" className="w-20" />
      </div>
      <div className="flex justify-between items-center gap-4 w-3/6">
        <form className="flex items-center gap-4 w-full relative">
          <input
            type="text"
            placeholder="Search"
            className="input input-bordered border-lightGray w-full pl-5 "
          />
          <span className=" absolute top-0 mt-3 left-0">
            <CiSearch size={20} className="text-lightGray" />
          </span>
        </form>
        <div className="flex justify-between items-center gap-4">
          <div className="avatar online">
            <div className="w-10 rounded-full">
              <img
                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="w-[1px] h-10 bg-lightGray"></div>

        <button className="btn btn-outline border-none text-lightGreen">
          Logout
        </button>
      </div>
    </div>
  );
};

export default TopHeader;
