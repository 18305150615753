import { Route, Routes } from "react-router-dom";
import Main from "./components/Main/Main";
import Login from "./pages/Auth/Login";

import "./index.css";
import Register from "./pages/Auth/Register";
import KYCAuth from "./pages/Auth/KYC";
import ForgetPassword from "./pages/Auth/ForgotPassword";
import CreateNewPassword from "./pages/Auth/CreateNewPassword";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fragment } from "react";
import Dashboard from "./pages/Dashboard/Index";
import Token from "./pages/Auth/Token";
function App() {
  return (
    <Fragment>
      <ToastContainer />
      <Routes>
        <Route path="/*" element={<Main />} />

        {/* auth routes */}
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/signup" element={<Register />} />
        <Route path="/auth/question" element={<KYCAuth />} />
        <Route path="/auth/forgetpassword" element={<ForgetPassword />} />
        <Route path="/auth/resetpassword" element={<CreateNewPassword />} />
        <Route path="/auth/token/:token" element={<Token />} />

        {/* dashboard routes */}
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </Fragment>
  );
}

export default App;
