import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopHeader from "../components/Dashboard/TopHeader";
import TopNavigationBar from "../components/Dashboard/TopNavigationBar";

const DashboardProvider = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/auth/login");
    }
  }, []); // eslint-disable-line
  return (
    <Fragment>
      <TopHeader />
      <div className="bg-[#F9FAFB] mt-10 pt-10">
        <TopNavigationBar />
        {children}
      </div>
    </Fragment>
  );
};

export default DashboardProvider;
