import React from "react";
import MyCoursesLayout from "../../Layouts/Dashboard/MyCoursesLayout";

const MyCourses = () => {
  return (
    <MyCoursesLayout>
      <div className="bg-white py-3 px-3 my-4 rounded-md w-[95%] mx-auto ">
        <h1 className="text-xl font-bold text-dark">My Courses</h1>
      </div>
      <div className=" py-3 w-full    mx-auto">
        <section className="px-4 mt-6 w-[95%] mx-auto">
          <div className="grid grid-cols-2 justify-between items-center mt-4 gap-4">
            <button className="btn btn-outline bg-lightGreen text-white hover:bg-lightGreen w-full">
              Completed
            </button>
            <button className="btn btn-outline border-none hover:text-lightGreen w-full">
              InComplete
            </button>
          </div>

          <div className="bg-white py-3 w-full  rounded-md mt-4 relative px-4 space-y-4">
            <span className="text-sm text-lightGreen absolute top-0 right-0">
              25%
            </span>
            <div className="h-1 w-full bg-lightGray2 rounded-md mt-2">
              <div className="w-1/4 h-full bg-lightGreen rounded-md"></div>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div>
                <div className="clear-both"></div>
                <h1 className="text-xl font-bold text-neutral">
                  Administration For Beginners
                </h1>
                <p className="text-sm text-lightGray">
                  Module 2 - Lesson 1: Introduction To Management Practices
                </p>
              </div>
              <div className="flex justify-start items-center gap-3">
                <button className="btn btn-outline">Course Details</button>
                <button className="btn btn-outline bg-lightGreen text-white hover:bg-lightGreen">
                  Resume Learning
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white py-3 w-full  rounded-md mt-4 relative px-4 space-y-4">
            <span className="text-sm text-lightGreen absolute top-0 right-0">
              75%
            </span>
            <div className="h-1 w-full bg-lightGray2 rounded-md mt-2">
              <div className="w-3/4 h-full bg-lightGreen rounded-md"></div>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div>
                <div className="clear-both"></div>
                <h1 className="text-xl font-bold text-neutral">
                  Effective Communication In The Workplace
                </h1>
                <p className="text-sm text-lightGray">
                  Module 1 - Lesson 8: Formal Speech Practice
                </p>
              </div>
              <div className="flex justify-start items-center gap-3">
                <button className="btn btn-outline">Course Details</button>
                <button className="btn btn-outline bg-lightGreen text-white hover:bg-lightGreen">
                  Resume Learning
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white py-3 w-full  rounded-md mt-4 relative px-4 space-y-4">
            <span className="text-sm text-lightGreen absolute top-0 right-0">
              100%
            </span>
            <div className="h-1 w-full bg-lightGray2 rounded-md mt-2">
              <div className="w-full h-full bg-lightGreen rounded-md"></div>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div>
                <div className="clear-both"></div>
                <h1 className="text-xl font-bold text-neutral">
                  Clerical Tasks In The Office
                </h1>
                <p className="text-sm text-lightGray">Completed</p>
              </div>
              <div className="flex justify-start items-center gap-3">
                <button className="btn btn-outline">Course Details</button>
                <button className="btn btn-outline bg-lightGreen text-white hover:bg-lightGreen">
                  View Certificate
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="w-full md:w-1/4 px-5 py-3 shadow-md">ok</div> */}
      </div>
    </MyCoursesLayout>
  );
};

export default MyCourses;
