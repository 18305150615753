import React from "react";
import logo from "../../assets/image/logo/logo.png";
import { FaGoogle } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import loginImage from "../../assets/authImages/loginImage.png";
import { MdAlternateEmail } from "react-icons/md";
import { useForm } from "react-hook-form";
import { loginSchema } from "../../schema/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../components/Shared/Loading";
import { toast } from "react-toastify";
import { axiosInsatnce } from "../../config/axios";
import { Link, useNavigate } from "react-router-dom";
import AuthProvider from "../../Provider/AuthProvider";

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const handleGoogleAuth = () => {
    window.open(`http://13.60.54.2/api/v1/auth/google`, "_self");
  };

  const onSubmit = async (data) => {
    try {
      const reponse = await axiosInsatnce.post("auth/login", data);
      localStorage.setItem("userId", reponse.data.data._id);
      const token = reponse.data.token;
      localStorage.setItem("token", token);
      toast.success("Welcome 😊");
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      toast.error("Invalid email or password");
    }
  };
  return (
    <AuthProvider>
      <div className="container mx-auto p-1">
        <div className="grid grid-cols-1 md:grid-cols-2 justify-between items-center md:gap-10">
          <div className="w-full md:w-[450px] mx-auto px-1">
            <div className="w-36 h-16">
              <img src={logo} alt="logo" className="w-full h-full" />
            </div>

            <div className="flex flex-col justify-center items-center">
              <div className="space-y-1 text-center">
                <h1 className="font-bols text-2xl py-2 text-dark">
                  Welcome Back
                </h1>
                <p className="text-gray-400 font-semibold">
                  New to our platform?{"  "}
                  <Link to={"/auth/signup"} className="text-lightGreen">
                    {" "}
                    Sign Up
                  </Link>
                </p>
              </div>
              {/* google auth button */}
              <div className="mt-4 w-full">
                <button
                  className="btn btn-block btn-outline border border-neutral rounded-md text-base font-semibold"
                  onClick={handleGoogleAuth}
                >
                  <FaGoogle className="mr-2" size={20} />
                  Sign In With Google
                </button>
              </div>
              <div className="divider my-2"></div>
              <form
                className="w-full space-y-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="border-none w-full relative">
                  <label className="label">
                    <span className="label-text">Email or Phone number</span>
                  </label>
                  <span className="text-error">{errors.email?.message}</span>
                  <input
                    type="email"
                    placeholder="Email"
                    className="input input-bordered w-full focus:outline-none pl-12"
                    {...register("email")}
                  />

                  <span className="absolute top-[55%] left-6 font-bold mt-1 text-lightGray">
                    <MdAlternateEmail className="w-5 h-5" />
                  </span>
                </div>

                <div className="border-none relative w-full">
                  <label className="label">
                    <span className="label-text">Password</span>
                  </label>
                  <span className="text-error">{errors.password?.message}</span>
                  <input
                    type="password"
                    placeholder="Password"
                    className="input input-bordered pl-12 text-lightGray font-semibold w-full  focus:outline-none"
                    {...register("password")}
                  />

                  <span className="absolute top-[55%] left-6 font-bold mt-1 text-lightGray">
                    <CiLock className="w-5 h-5" />
                  </span>
                </div>

                <div className="flex justify-end items-center">
                  <Link
                    to="/auth/forgetpassword"
                    className="text-lightGreen text-sm font-semibold"
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="mt-3 w-full  mx-auto">
                  {isSubmitting ? (
                    <div className="flex justify-center items-center flex-col">
                      <Loading />
                    </div>
                  ) : (
                    <button className="btn btn-outline hover:text-white text-white hover:bg-lightGreen hover:opacity-90 bg-lightGreen rounded-md btn-block">
                      Log in
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="w-full hidden md:block relative h-screen">
            <img
              src={loginImage}
              alt="login"
              className="w-full h-full object-cover rounded-md"
            />

            <div className="absolute bottom-0 left-0 w-full  bg-[#01473799] bg-opacity-60 rounded-md px-4 py-12">
              <p className="font-bold text-3xl text-white leading-[40px]">
                “The tallest trees sometimes grow from the smallest seeds”
              </p>

              <p className="text-white font-semibld text-xl mt-3">
                ---- Matshona Dhliwayo
              </p>
            </div>
          </div>
        </div>
      </div>
    </AuthProvider>
  );
};

export default Login;
