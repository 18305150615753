export const landingCourseCategory = [
  {
    id: 1,
    category: "Public Speaking",
  },
  {
    id: 2,
    category: "Frontend Engineering",
  },
  {
    id: 3,
    category: "Entrepreneurship",
  },
  {
    id: 4,
    category: "Craftwork",
  },
  {
    id: 5,
    category: "SME Management",
  },
  {
    id: 6,
    category: "Graphic Design",
  },
  {
    id: 7,
    category: "Content Writing",
  },
  {
    id: 8,
    category: "Technical",
  },
  {
    id: 9,
    category: "Catering",
  },
  {
    id: 10,
    category: "Human Resource Management",
  },
  {
    id: 11,
    category: "Computer Introduction",
  },
  {
    id: 12,
    category: "Film Making",
  },
];

export const landingReviews = [
  {
    id: 1,
    title: "Colour Grading with Computational Photography",
    category: "Beginner",
    rating: 4,
    learners: "1.2k",
    colorCode: "#03543F",
  },
  {
    id: 2,
    title: "Public Speaking And Engagement",
    category: "Intermediate",
    rating: 4,
    learners: "1.2k",
    colorCode: "#03543F",
  },
  {
    id: 3,
    title: "Catering Etiquette and Best Practices",
    category: "Beginner",
    rating: 4,
    learners: "1.2k",
    colorCode: "#03543F",
  },
  {
    id: 4,
    title: "Shorthand Writing for On-the-Go Efficiency",
    category: "Expert",
    rating: 4,
    learners: "1.2k",
    colorCode: "#FCE8F3",
  },
];

export const features = [
  {
    id: 1,
    title: "Become An Instructor",
    description:
      "Create impact along with thousands of people around the world and teach millions of students",
    buttonText: "Start Teaching Today",
    image: "img3",
  },
  {
    id: 2,
    title: "Transform Access To Education",
    description:
      "Create an account to receive our official newsletter, course recommendations, and promotions",
    buttonText: "Register Now",
    image: "img4",
  },
];
