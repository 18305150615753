export const dashboardTopNavbar = [
  {
    id: 1,
    name: "OverView",
    path: "/dashboard",
  },
  {
    id: 2,
    name: "My Courses",
    path: "/dashboard/courses",
  },
  {
    id: 3,
    name: "Certificates",
    path: "/dashboard/certificates",
  },
  {
    id: 4,
    name: "Explore",
    path: "/dashboard/explore",
  },
  {
    id: 5,
    name: "Forum",
    path: "/dashboard/forum",
  },
  {
    id: 6,
    name: "Support",
    path: "/dashboard/support",
  },
];
