import React from "react";
import { CiMenuBurger } from "react-icons/ci";
import SideNavigationBar from "../../components/Dashboard/SideNavigationBar";

const OverViewLayout = ({ children }) => {
  return (
    <section className="container mx-auto mt-4">
      <div className="drawer lg:drawer-open overflow-hidden">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content md:pl-5 mt-1 gap-5">
          {children}
          <label
            htmlFor="my-drawer-2"
            className="btn btn-outline drawer-button lg:hidden fixed top-0 left-0 "
          >
            <CiMenuBurger />
          </label>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <SideNavigationBar />
        </div>
      </div>
    </section>
  );
};

export default OverViewLayout;
