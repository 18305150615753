export const courseCategory = [
  "Public Speaking",
  "Frontend Engineering",
  "Entrepreneurship",
  "SME Management",
  "Graphic Design",
  "Content Writing",
  "Catering",
  "Human Resource Management",
  "Computer Introduction",
];
