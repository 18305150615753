import React from "react";
import DashboardProvider from "../../Provider/DashboardProvider";
import { Route, Routes } from "react-router-dom";
import OverView from "./OverView";
import MyCourses from "./MyCourses";
import MyCourseDetail from "./MyCourseDetail";
import Certificate from "./Certificate";

const Dashboard = () => {
  return (
    <DashboardProvider>
      <Routes>
        <Route path="/" element={<OverView />} />
        <Route path="/courses" element={<MyCourses />} />
        <Route path="/courses/:courseId" element={<MyCourseDetail />} />
        <Route path="certificates" element={<Certificate />} />
      </Routes>
    </DashboardProvider>
  );
};

export default Dashboard;
