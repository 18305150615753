import React from "react";
import ReactStars from "react-stars";

const CourseReview = () => {
  return (
    <div className="w-1/4 bg-white rounded py-3 mt-2 flex px-1 flex-col">
      <h1 className="text-xl font-bold text-dark mb-3">Reviews</h1>
      {[1, 2].map((item) => (
        <div className="mb-5" key={item}>
          <div className="flex justify-start items-end gap-4">
            <div className="flex justify-start items-center gap-2">
              <div className="avatar">
                <div className="w-10 rounded-full">
                  <img
                    src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <h4 className="text-sm font-semibold text-dark">Jese Leos</h4>
                <p className="text-lightGray text-[12px]">Posted Today</p>
              </div>
            </div>

            <div className="flex justify-start items-center gap-2">
              <h4 className="text-sm font-semibold text-dark">4/5</h4>
              <ReactStars
                count={5}
                size={24}
                color2={"#ffd700"}
                value={4}
                edit={false}
              />
            </div>
          </div>

          <p className="text-lightGray text-sm mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. In nibh
            mauris cursus mattis. Proin sagittis nisl rhoncus mattis rhoncus
            urna neque. Lacus sed turpis tincidunt.
            <span className="text-lightGreen cursor-pointer block italic">
              Read More.....
            </span>
          </p>

          <div className="h-[1px] w-full bg-lightGray opacity-40 mt-3"></div>
        </div>
      ))}
    </div>
  );
};

export default CourseReview;
