import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const FlipEffect = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Animation only once
    });
  }, []);

  return (
    <div
      data-aos="flip-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000"
      data-aos-delay="0"
      data-aos-once="false"
    >
      {children}
    </div>
  );
};

const FadeUpEffect = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Animation only once
    });
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000"
      data-aos-delay="0"
      data-aos-once="false"
    >
      {children}
    </div>
  );
};

const FadeDownEffect = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Animation only once
    });
  }, []);

  return (
    <div
      data-aos="fade-down"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="1000"
      data-aos-delay="0"
      data-aos-once="false"
    >
      {children}
    </div>
  );
};
export { FlipEffect, FadeUpEffect, FadeDownEffect };
