import React from "react";
import ReactPlayer from "react-player/youtube";
const VideoPlayer = () => {
  return (
    <ReactPlayer
      url="https://youtu.be/cCyYGYyCka4?si=1oe9blWXbeKeKdWX"
      width={"100%"}
      height={"100%"}
      controls
    />
  );
};
export default VideoPlayer;
